import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import React from 'react'

import { fromPairs } from '@livechat/data-utils'

import WidgetPreview from 'components/WidgetPreview'
import { ChevronIcon } from 'components/icons'
import { usePublicOrganizationEntities } from 'hooks/usePublicOrganizationEntities'
import { Drawer } from 'layouts/components/Drawer'
import { Foreground } from 'layouts/components/Foreground'
import { Section } from 'layouts/components/Section'
import {
	Content,
	DrawerBottom,
	Main,
	MobileContent,
	Plane,
	Title,
	WidgetContainer,
} from 'layouts/components/SharedStyledComponents'
import { WidgetPlane } from 'layouts/components/WidgetPlane'
import { Feature, FeatureName } from 'types/feature'

const StyledDrawerBottom = styled(DrawerBottom)<{ isDrawerExpanded: boolean }>`
	justify-content: center;
	bottom: ${({ theme }) => theme.spaces.space3};
	position: fixed;
	left: ${({ theme }) => theme.spaces.space3};
	right: ${({ theme }) => theme.spaces.space3};
	border-radius: ${({ theme }) => theme.borderRadiuses.lg};
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};

	svg {
		scale: 1.2;
		will-change: rotate;
		transition: rotate 200ms ease;

		${({ isDrawerExpanded }) =>
			!isDrawerExpanded &&
			css`
				rotate: 180deg;
			`}
	}
`

type Props = {
	children: React.ReactNode
}

function ShareLayout({ children }: Props) {
	const router = useRouter()
	const organizationId = router.query.organizationId as string | undefined
	const { organizationEntities } = usePublicOrganizationEntities(organizationId)
	const theme = useTheme()
	const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(true)

	const widgetFeaturesProps = React.useMemo((): React.ComponentProps<typeof WidgetPreview> | undefined => {
		if (organizationEntities) {
			const features = fromPairs(organizationEntities.features.map((feature) => [feature.name, feature])) as {
				[F in FeatureName]: Feature<F>
			}

			return {
				faq: features.faq,
				visitorCounter: features.visitor_counter,
				instagramFeed: features.instagram_feed,
				productRecommendations: features.product_recommendations,
				googleReviews: features.google_reviews,
				forms: features.forms,
				productCards: features.product_cards,
				links: features.links,
				openaiFeature: features?.openai_integration,
				openaiIntegration: {
					connected: features.openai_integration?.enabled,
				},
				livechatIntegration: {
					connected: features.livechat_integration?.enabled,
					isLicenseActive: features.livechat_integration?.enabled,
				},
			}
		}
	}, [organizationEntities])

	if (theme.isMobile) {
		return (
			<>
				<Foreground onClick={() => setIsDrawerExpanded(false)}>
					<Section>
						<WidgetPlane pushed={isDrawerExpanded}>
							<WidgetPreview
								organizationId={organizationId}
								theme={organizationEntities?.theme}
								header={organizationEntities?.header}
								templateLanguage={organizationEntities?.language}
								isChatOnlyModeEnabled={organizationEntities?.isChatOnlyModeEnabled}
								{...widgetFeaturesProps}
							/>
						</WidgetPlane>
					</Section>
				</Foreground>
				<Drawer
					expanded={isDrawerExpanded}
					css={css({
						backgroundColor: theme.colors.light.primaryBackgroundColor,
						paddingTop: '16px',
					})}
				>
					<Title>Add to website</Title>
					<StyledDrawerBottom
						isDrawerExpanded={isDrawerExpanded}
						onClick={() => setIsDrawerExpanded((state) => !state)}
					>
						<ChevronIcon direction={isDrawerExpanded ? 'downwards' : 'upwards'} />
					</StyledDrawerBottom>
					<MobileContent>{children}</MobileContent>
				</Drawer>
			</>
		)
	}

	return (
		<>
			<Main>
				<Plane css={css({ marginLeft: '72px' })}>
					<Title>Add to website</Title>
					<Content>{children}</Content>
				</Plane>
				<WidgetContainer>
					<WidgetPreview
						organizationId={organizationId}
						theme={organizationEntities?.theme}
						header={organizationEntities?.header}
						previewUrl={organizationEntities?.website}
						templateLanguage={organizationEntities?.language}
						isChatOnlyModeEnabled={organizationEntities?.isChatOnlyModeEnabled}
						{...widgetFeaturesProps}
					/>
				</WidgetContainer>
			</Main>
		</>
	)
}

export default ShareLayout
