import { css, keyframes, useTheme as useAppTheme } from '@emotion/react'
import styled from '@emotion/styled'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { createContext } from 'react'

import { noop } from '@livechat/data-utils'
import { promiseRetry } from '@livechat/promise-utils'

import HoverableItem from '../components/HoverableItem'
import { useIntegration } from '../hooks/useIntegration'
import { Drawer, DrawerNav } from './components/Drawer'
import { Foreground } from './components/Foreground'
import { Section } from './components/Section'
import { Content, DrawerBottom, Main, MobileContent, Plane, Title } from './components/SharedStyledComponents'
import SuccessStep from './components/SuccessStep'
import { WidgetPlane } from './components/WidgetPlane'
import BetaBadge from 'components/BetaBadge'
import { PortalOutlet, PortalProvider, createPortalContext } from 'components/Portal'
import AnimatedConfiguratorViewWrapper from 'components/SlideInWrapper'
import { UserPopoverSection } from 'components/UserPopover'
import { ArchiveIcon, ArticleIcon, CaretLeftIcon, CodeIcon, PaletteIcon, UserAvatarIcon } from 'components/icons'
import { useInstallPrompt } from 'hooks/useInstallPrompt'
import { useScrollTopOnce } from 'hooks/useScrollTopOnce'
import useTemplateId from 'hooks/useTemplateId'
import { useTemplates } from 'hooks/useTemplates'
import { useTracking } from 'hooks/useTracking'
import { useUser } from 'hooks/useUser'
import { LIVECHAT_BETA_BADGE_TOOLTIP_TEXT, OPENAI_BETA_BADGE_TOOLTIP_TEXT } from 'lib/constants'
import { useAppSelector } from 'lib/store'
import { EVENT_NAMES } from 'lib/tracking'
import { Theme } from 'styles/theme'

const TemplateChooser = dynamic(() => promiseRetry(() => import('components/TemplateChooser')), {
	ssr: false,
})

const Nav = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
	gap: ${({ theme }) => theme.spaces.space5};
	padding: ${({ theme }) => theme.spaces.space5};
	box-shadow: ${({ theme }) => theme.boxShadows.lg};
	border-radius: ${({ theme }) => theme.borderRadiuses.xl};

	@media (max-width: 900px) {
		padding: ${({ theme }) => theme.spaces.space3};
	}
`

const navLinkStyles = (theme: Theme, isActive: boolean) => css`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: inherit;
	text-decoration: inherit;
	padding: ${theme.spaces.space2};
	border-radius: ${theme.borderRadiuses.xl};
	background-color: ${isActive ? theme.colors.light.surfaceDecorative : 'transparent'};
	position: relative;

	svg {
		z-index: ${theme.zIndexes.high};
	}
`

const ActionButons = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${({ theme }) => (theme.isMobile ? 'space-between' : 'flex-end')};
	gap: ${({ theme }) => theme.spaces.space3};
	padding-right: ${({ theme }) => theme.spaces.space6};
`

const DrawerTop = styled(DrawerNav)`
	border: none;
	visibility: ${({ expanded }: { expanded?: boolean }) => (expanded ? 'visible' : 'hidden')};
`

const StyledDrawerBottom = styled(DrawerBottom)`
	bottom: ${({ theme }) => theme.spaces.space3};
	position: fixed;
	left: ${({ theme }) => theme.spaces.space3};
	right: ${({ theme }) => theme.spaces.space3};
	border-radius: ${({ theme }) => theme.borderRadiuses.lg};
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
`

const bubbleScale = keyframes`
	0% {
		scale: 1
	}
	100% {
		scale: 1.4
	}
`

const OuterBubble = styled.div`
	position: absolute;
	width: 30px;
	height: 30px;
	background-color: #eff0fd;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	animation: ${bubbleScale} linear 1s infinite alternate 1s;
`

const InnerBubble = styled.div`
	position: absolute;
	width: 30px;
	height: 30px;
	background-color: #d3d5fc;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	animation: ${bubbleScale} linear 1s infinite alternate;
`

const BubbleWrapper = styled.div`
	position: absolute;
	display: grid;
	background-color: ${({ theme }) => theme.colors.light.primaryBackgroundColor};
	place-items: center;
	width: calc(100% + 2 * ${({ theme }) => (theme.isMobile ? theme.spaces.space6 : theme.spaces.space5)});
	height: calc(100% + 2 * ${({ theme }) => (theme.isMobile ? theme.spaces.space6 : theme.spaces.space5)});
	border-radius: ${({ theme }) => theme.borderRadiuses.xl};
	z-index: ${({ theme }) => theme.zIndexes.above};
`

const NotificationDot = styled.div`
	position: absolute;
	top: -2px;
	right: -2px;
	width: 10px;
	height: 10px;
	border-radius: ${({ theme }) => theme.borderRadiuses.round};
	background-color: ${({ theme }) => theme.colors.light.notification};
`

const HeadingWrapper = styled.div`
	height: 50px;
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space3};
	justify-content: ${({ theme }) => (theme.isMobile ? 'flex-start' : 'space-between')};
	border-bottom: 1px solid ${({ theme }) => theme.colors.light.borderColor};
`

const BreadcrumbsWrapper = styled.nav`
	display: flex;
	gap: ${({ theme }) => theme.spaces.space3};
`

const breadcrumbStyles = (theme: Theme) => css`
	display: flex;
	gap: ${theme.spaces.space2};
	align-items: center;
	color: ${theme.colors.light.breadcrumb};
	text-decoration: none;
`

const BreadcrumbCurrent = styled.span`
	${({ theme }) => theme.typography.smallHeading};
	color: ${({ theme }) => theme.colors.light.primaryTextColor};
	white-space: nowrap;
	align-self: center;
`

const Column = styled.div<{ hasTemplateChooser?: boolean }>`
	height: 100%;
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spaces.space5};
	padding-bottom: ${({ hasTemplateChooser }) => (hasTemplateChooser ? '68px' : 0)};
`

const MobileTemplateChooserWrapper = styled.div`
	padding-right: ${({ theme }) => theme.spaces.space6};
	margin-top: ${({ theme }) => theme.spaces.space3};
`

const routes = [
	{ route: '/chat-archives/openai', title: 'OpenAI Assistant chats', icon: <ArchiveIcon size={24} /> },
	{
		route: '/configurator/widget-content',
		title: 'Widget content',
		icon: <ArticleIcon />,
		children: [
			{
				pathName: 'contact',
				title: 'Contact card',
			},
			{
				pathName: 'whatsapp',
				title: 'WhatsApp',
			},
			{
				pathName: 'messenger',
				title: 'Messenger',
			},
			{
				pathName: 'faq',
				title: 'FAQ',
			},
			{
				pathName: 'product',
				title: 'Product carousel',
			},
			{
				pathName: 'visitor-counter',
				title: 'Visitor counter',
			},
			{
				pathName: 'instagram',
				title: 'Instagram',
			},
			{
				pathName: 'product-recommendations',
				title: 'Product recommendations',
			},
			{
				pathName: 'helpdesk',
				title: 'HelpDesk',
			},
			{
				pathName: 'google-reviews',
				title: 'Google reviews',
			},
			{
				pathName: 'livechat',
				title: 'LiveChat',
			},
			{
				pathName: 'chatbot',
				title: 'ChatBot',
			},
			{
				pathName: 'apps',
				title: 'Apps',
			},
			{
				pathName: 'openai-assistants',
				title: 'OpenAI Assistants',
			},
			{
				pathName: 'custom-links',
				title: 'Custom links',
			},
		],
	},
	{
		route: '/configurator/customize-look',
		title: 'Customize look',
		icon: <PaletteIcon />,
		children: [
			{
				pathName: 'advanced',
				title: 'Widget position and visibility',
			},
		],
	},
	{ route: '/configurator/code-snippet', title: 'Add to website', icon: <CodeIcon />, notificationDot: true },
] as const

type Routes = typeof routes

type ArchivesRoutesConfig = Routes['0']
type WidgetContentRoutesConfig = Routes['1']
type CustomizeLookRoutesConfig = Routes['2']

export type WidgetContentRoutes =
	`${WidgetContentRoutesConfig['route']}/${WidgetContentRoutesConfig['children'][number]['pathName']}${
		| ''
		| `/${string}`}`
export type CustomizeLookRoutes = `${CustomizeLookRoutesConfig['route']}${'' | `/${string}`}`
export type ArchivesRoutes = `${ArchivesRoutesConfig['route']}${'' | `/${string}`}`

type WidgetContentRouteTitles =
	| WidgetContentRoutesConfig['children'][number]['title']
	| CustomizeLookRoutesConfig['children'][number]['title']
type NestedRoutes = WidgetContentRoutesConfig['children'][number] | CustomizeLookRoutesConfig['children'][number]

const BETA_BADGE_TOOLTIP_TEXTS: Partial<Record<WidgetContentRouteTitles, string>> = {
	LiveChat: LIVECHAT_BETA_BADGE_TOOLTIP_TEXT,
	'OpenAI Assistants': OPENAI_BETA_BADGE_TOOLTIP_TEXT,
}

const USER_ROUTE = '/configurator/user'

export const ActionButtonsPortalContext = createPortalContext()
export const HeadingControlPortalContext = createPortalContext()
export const DrawerContext = createContext<(value: boolean) => void>(noop)

function HeadingBreadcrumbs({ path }: { path: string }) {
	const { withTemplateId } = useTemplateId()
	const route = routes.find(({ route }) => path.startsWith(route))
	const isNestedRoute = route && 'children' in route && route.children.some(({ pathName }) => path.includes(pathName))
	const track = useTracking()

	const bareHeading = (
		<HeadingWrapper>
			<Title>{route?.title}</Title>
		</HeadingWrapper>
	)

	if (!isNestedRoute) {
		return bareHeading
	}

	const [, , previousRoute, currentRoute] = path.split('/')

	if (!previousRoute || !currentRoute) {
		return bareHeading
	}

	const currentRouteName = (route.children as Readonly<NestedRoutes[]>).find(({ pathName }) =>
		currentRoute.replace('#', '').endsWith(pathName),
	)?.title

	const betaBadgeTooltipText = currentRouteName && BETA_BADGE_TOOLTIP_TEXTS[currentRouteName]

	const handleBreadcrumbClick = () => {
		track(EVENT_NAMES.NAVIGATION_BACK_CLICKED, { sectionName: currentRoute })
	}

	return (
		<AnimatedConfiguratorViewWrapper>
			<HeadingWrapper>
				<BreadcrumbsWrapper>
					<Link
						href={withTemplateId(path.split('/').slice(0, -1).join('/'))}
						css={breadcrumbStyles}
						aria-label="navigate back to widget content"
						onClick={handleBreadcrumbClick}
					>
						<CaretLeftIcon />
					</Link>
					<BreadcrumbCurrent>{currentRouteName}</BreadcrumbCurrent>
					{betaBadgeTooltipText && <BetaBadge withIcon tooltipText={betaBadgeTooltipText} />}
				</BreadcrumbsWrapper>
				<PortalOutlet context={HeadingControlPortalContext} />
			</HeadingWrapper>
		</AnimatedConfiguratorViewWrapper>
	)
}

function DefaultLayout({ leftColumn, rightColumn }: { leftColumn: React.ReactNode; rightColumn: React.ReactNode }) {
	const router = useRouter()
	const user = useUser()
	const appTheme = useAppTheme()
	const contentRef = React.useRef<HTMLElement>(null)
	const isSuccessStepActive = useAppSelector((state) => state.currentModal === 'SuccessStepModal')
	const { isVisible: isInstallPromptVisible } = useInstallPrompt()
	const { withTemplateId } = useTemplateId()
	const { templates } = useTemplates()
	const openAIIntegration = useIntegration('openai')
	const hasMultipleTemplates = typeof templates !== 'undefined' && templates.length > 1
	const [isDrawerExpanded, setIsDrawerExpanded] = React.useState(isSuccessStepActive ? false : true)

	useScrollTopOnce(contentRef)

	const allowedRoutes = React.useMemo(
		() =>
			routes.filter((routeConfig) => {
				if (routeConfig.route.startsWith('/chat-archives/') && !openAIIntegration.integration?.connected) {
					return false
				}

				return true
			}),
		[openAIIntegration],
	)

	if (appTheme.isMobile) {
		const shouldDisplayTemplateChooser = hasMultipleTemplates && !router.route.endsWith('user')
		return (
			<DrawerContext.Provider value={setIsDrawerExpanded}>
				<Foreground onClick={() => setIsDrawerExpanded(false)}>
					<Section>
						<WidgetPlane pushed={isDrawerExpanded}>{rightColumn}</WidgetPlane>
					</Section>
				</Foreground>
				<PortalProvider context={ActionButtonsPortalContext}>
					<PortalProvider context={HeadingControlPortalContext}>
						<Drawer
							expanded={isDrawerExpanded}
							compact={router.route.startsWith(USER_ROUTE)}
							onClick={() => setIsDrawerExpanded(true)}
							css={css({
								backgroundColor: appTheme.colors.light.primaryBackgroundColor,
								paddingTop:
									isDrawerExpanded && !router.route.includes('code-snippet') && !router.route.endsWith('user')
										? '55px'
										: '16px',
							})}
						>
							<DrawerTop expanded={isDrawerExpanded} isElevated={isSuccessStepActive}>
								<PortalOutlet context={ActionButtonsPortalContext} Component={ActionButons} />
							</DrawerTop>
							{!router.route.endsWith('user') && <HeadingBreadcrumbs path={router.pathname} />}
							{shouldDisplayTemplateChooser && (
								<MobileTemplateChooserWrapper>
									<TemplateChooser templates={templates} />
								</MobileTemplateChooserWrapper>
							)}
							{user.role === 'administrator' && (
								<StyledDrawerBottom isElevated={isSuccessStepActive}>
									{allowedRoutes.map((routeConfig) => {
										const { route, icon } = routeConfig
										return (
											<Link
												key={route}
												href={router.route.startsWith(route) ? router.asPath : withTemplateId(route)}
												css={navLinkStyles(appTheme, router.route.startsWith(route))}
											>
												{icon}
												{isSuccessStepActive && router.route.startsWith(route) && (
													<BubbleWrapper>
														<OuterBubble />
														<InnerBubble />
													</BubbleWrapper>
												)}
												{'notificationDot' in routeConfig && routeConfig.notificationDot && isInstallPromptVisible && (
													<NotificationDot data-testid="notification-dot" />
												)}
											</Link>
										)
									})}
									<SuccessStep />
									<Link href={USER_ROUTE} css={navLinkStyles(appTheme, router.route.startsWith(USER_ROUTE))}>
										<UserAvatarIcon />
									</Link>
								</StyledDrawerBottom>
							)}
							<MobileContent
								ref={contentRef}
								compact={router.route.startsWith(USER_ROUTE)}
								shrinkHeight={shouldDisplayTemplateChooser}
							>
								{leftColumn}
							</MobileContent>
						</Drawer>
					</PortalProvider>
				</PortalProvider>
			</DrawerContext.Provider>
		)
	}

	return (
		<>
			<UserPopoverSection />
			<Main>
				{user.role === 'administrator' && (
					<Nav>
						{allowedRoutes.map((routeConfig) => {
							const { title, route, icon } = routeConfig
							return (
								<HoverableItem title={title} key={route}>
									<Link
										href={withTemplateId(route)}
										css={navLinkStyles(appTheme, router.route.startsWith(route))}
										aria-label={title}
									>
										{icon}
										{isSuccessStepActive && router.route.startsWith(route) && (
											<BubbleWrapper>
												<OuterBubble />
												<InnerBubble />
											</BubbleWrapper>
										)}
										{'notificationDot' in routeConfig && routeConfig.notificationDot && isInstallPromptVisible && (
											<NotificationDot data-testid="notification-dot" />
										)}
									</Link>
								</HoverableItem>
							)
						})}
					</Nav>
				)}
				<Column hasTemplateChooser={hasMultipleTemplates}>
					{hasMultipleTemplates && <TemplateChooser templates={templates} />}
					<PortalProvider context={ActionButtonsPortalContext}>
						<PortalProvider context={HeadingControlPortalContext}>
							<Plane>
								<HeadingBreadcrumbs path={router.pathname} />
								<Content ref={contentRef}>{leftColumn}</Content>
								<PortalOutlet context={ActionButtonsPortalContext} Component={ActionButons} />
							</Plane>
						</PortalProvider>
					</PortalProvider>
				</Column>
				{rightColumn}
			</Main>
		</>
	)
}

export default DefaultLayout
