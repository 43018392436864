import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { AnimatePresence } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import Confetti from 'react-dom-confetti'
import { useStore } from 'react-redux'

import Button from 'components/Button'
import ModalManager from 'components/ModalManager'
import { useInstallations } from 'hooks/useInstallations'
import { useTracking } from 'hooks/useTracking'
import { DarkBackground, Title } from 'layouts/components/SharedStyledComponents'
import { config } from 'lib/config'
import { useAppSelector } from 'lib/store'
import { EVENT_NAMES } from 'lib/tracking'
import { formControlReset } from 'styles/reset'

const CONFETTI_ENTRY_TIMEOUT = 800
const CONFETTI_EXIT_TIMEOUT = 1000

const confettiSetup = {
	spread: 360,
	startVelocity: 50,
	elementCount: 200,
	duration: 4000,
}

const Caption = styled.p`
	color: ${({ theme }) => theme.colors.light.primaryTextColor};
	${({ theme }) => (theme.isMobile ? theme.typography.caption : theme.typography.paragraph)};

	a {
		color: inherit;
	}
`

const OuterSuccessStep = styled.div`
	${({ theme }) =>
		theme.isMobile &&
		css`
			position: fixed;
			top: 0;
			left: 0;
		`};

	@media (max-width: 900px) {
		margin-left: 64px;
	}
`

const BoostedConfetti = styled(Confetti)`
	z-index: ${({ theme }) => theme.zIndexes.floating};
	left: 70%;
`

const CtaText = styled.button`
	text-decoration: underline;
	${formControlReset}
	padding: 0;
	cursor: pointer;
	font-size: inherit;
`

const ShiningGif = styled.div`
	position: relative;
	width: 100%;
	height: 140px;

	img {
		object-fit: contain;
		border-radius: ${({ theme }) => theme.borderRadiuses.xl};
	}
`

const SuccessStep = () => {
	const [isConfettiReady, setIsConfettiReady] = React.useState(false)
	const theme = useTheme()
	const store = useStore()
	const track = useTracking()
	const { installations } = useInstallations()
	const isSuccessStepActive = useAppSelector((state) => state.currentModal === 'SuccessStepModal')

	const hideSuccessModal = () => {
		store.dispatch({ type: 'currentModal/set', payload: null })
	}

	const triggerWidget = () => {
		if ('OpenWidget' in window) {
			window.OpenWidget.call('maximize', { feature: 'form-contact' })
		}
		track(EVENT_NAMES.CONTACT_US_CLICKED, { source: 'SuccessModal' })
	}

	React.useEffect(() => {
		if (!isSuccessStepActive) {
			return
		}

		const entryTimeout = setTimeout(() => setIsConfettiReady(true), CONFETTI_ENTRY_TIMEOUT)
		const exitTimeout = setTimeout(() => setIsConfettiReady(false), CONFETTI_EXIT_TIMEOUT)

		return () => {
			clearTimeout(entryTimeout)
			clearTimeout(exitTimeout)
		}
	}, [isSuccessStepActive])

	const helpCta = theme.isMobile ? (
		<Link href="https://help.openwidget.com/" target="_blank" rel="noopener,noreferrer">
			Check out our Help Center!
		</Link>
	) : (
		<CtaText onClick={triggerWidget}>Leave us a message!</CtaText>
	)

	return (
		<AnimatePresence>
			{isSuccessStepActive && (
				<OuterSuccessStep>
					{!config.isTest && <BoostedConfetti active={isConfettiReady} config={confettiSetup} />}
					<DarkBackground
						initial={{ opacity: 0 }}
						animate={{ opacity: 0.7 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.5, ease: 'easeOut' }}
					/>
					<ModalManager
						modalName="SuccessStepModal"
						hideModal={hideSuccessModal}
						withoutBackdrop
						data-testid="SuccessStepModal"
					>
						<Title>Your new widget looks awesome! 🎉</Title>
						{installations && installations.length > 0 ? (
							<>
								<Caption>
									The widget is installed and visible on your website. Now we will take you to our configurator, where
									you can edit it anytime.
								</Caption>
								<Caption>Questions about the configuration? {helpCta}</Caption>
							</>
						) : (
							<>
								<Caption>Now, we will take you to our configurator, where you can edit it anytime.</Caption>
								<Caption>
									{
										"Remember! If you haven't done that already, go to the 'Add to website' section and learn how to install it on your site."
									}
								</Caption>
								<ShiningGif>
									<Image src="/images/shining.gif" draggable={false} alt="" fill />
								</ShiningGif>
								<Caption>Questions about the configuration or installation? {helpCta}</Caption>
							</>
						)}
						<Button onClick={hideSuccessModal} type="button" kind="primary" css={css({ alignSelf: 'flex-end' })}>
							Got it!
						</Button>
					</ModalManager>
				</OuterSuccessStep>
			)}
		</AnimatePresence>
	)
}

export default SuccessStep
