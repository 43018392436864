import { useRouter } from 'next/router'
import React from 'react'

import Redirect from './Redirect'
import { useTemplate } from 'hooks/useTemplate'
import { WidgetContentRoutes } from 'layouts/DefaultLayout'

function isAllowedInChatOnlyMode(pathname: string) {
	if (!pathname.startsWith('/configurator/widget-content') || pathname === '/configurator/widget-content') {
		return true
	}
	const chatIntegrationRoutes: WidgetContentRoutes[] = [
		'/configurator/widget-content/chatbot',
		'/configurator/widget-content/livechat',
		'/configurator/widget-content/openai-assistants',
	]
	return chatIntegrationRoutes.some((route) => pathname.startsWith(route))
}

type Props = {
	children: React.ReactNode
}
function FeatureAccessGuard({ children }: Props) {
	const router = useRouter()
	const { template, isTemplateLoading } = useTemplate()

	if (isTemplateLoading) {
		return null
	}
	if (template?.isChatOnlyModeEnabled && !isAllowedInChatOnlyMode(router.pathname)) {
		return <Redirect to="/configurator/widget-content" />
	}

	return <>{children}</>
}

export default FeatureAccessGuard
