import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { MotionProps, m } from 'framer-motion'

import { rotation } from 'styles/animations'
import { formControlReset } from 'styles/reset'

export type ButtonKind =
	| 'primary'
	| 'secondary'
	| 'accent'
	| 'secondary-accent'
	| 'success'
	| 'text'
	| 'destructive'
	| 'destructive-text'
	| 'destructive-secondary'
	| 'surface'

type Props = React.ButtonHTMLAttributes<HTMLButtonElement> &
	MotionProps & {
		loading?: boolean
		children: React.ReactNode
		compact?: boolean
		fullWidth?: boolean
		mediumDesktop?: boolean
		kind?: ButtonKind
		icon?: React.ReactNode
		disableHover?: boolean
		reverse?: boolean
	}

export const StyledButton = styled(m.button)<
	Pick<Props, 'kind' | 'compact' | 'loading' | 'disableHover' | 'mediumDesktop' | 'fullWidth'>
>`
	${formControlReset};
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: ${({ theme }) => theme.spaces.space2};
	padding: ${({ theme }) =>
		theme.isMobile ? `${theme.spaces.space3} ${theme.spaces.space5}` : `${theme.spaces.space6} ${theme.spaces.space7}`};
	border-radius: ${({ theme }) => theme.borderRadiuses.lg};
	transition: color 200ms ${({ theme }) => theme.transitions.easings.css.smooth};
	transition: opacity 200ms ${({ theme }) => theme.transitions.easings.css.smooth};
	transition: transform 200ms ${({ theme }) => theme.transitions.easings.css.spring};
	white-space: nowrap;

	${({ fullWidth }) =>
		fullWidth &&
		css`
			width: 100%;
		`}

	${({ disableHover }) =>
		!disableHover &&
		css`
			@media (hover: hover) {
				&:hover {
					transform: scale(1.1);
				}
			}
		`}

	&:disabled {
		cursor: not-allowed;

		@media (hover: hover) {
			&:hover {
				transform: none;
			}
		}
	}

	${({ theme }) => theme.typography.captionMedium}

	${({ theme, mediumDesktop }) =>
		!theme.isMobile &&
		mediumDesktop &&
		css`
			padding: ${theme.spaces.space4} ${theme.spaces.space5};
		`}

	${({ theme, compact }) =>
		compact &&
		css`
			padding: ${theme.spaces.space2} 6px;
			${theme.typography.smallcaps}
			@media (hover: hover) {
				&:hover {
					transform: scale(1);
				}
			}
		`}

	${({ theme, kind = 'secondary' }) => {
		if (kind === 'secondary') {
			return css`
				color: ${theme.colors.light.primaryTextColor};
				background-color: ${theme.colors.light.secondaryButtonBackground};
			`
		}

		if (kind === 'primary') {
			return css`
				color: ${theme.colors.dark.primaryTextColor};
				background-color: ${theme.colors.light.surfaceInteractive};
			`
		}

		if (kind === 'accent') {
			return css`
				color: ${theme.colors.dark.primaryTextColor};
				background-color: ${theme.colors.light.accent};
			`
		}

		if (kind === 'secondary-accent') {
			return css`
				color: #21095d;
				background-color: #ebebff;
			`
		}

		if (kind === 'success') {
			return css`
				color: ${theme.colors.dark.primaryTextColor};
				background-color: ${theme.colorPalette.green[500]};
			`
		}

		if (kind === 'text') {
			return css`
				color: ${theme.colors.light.primaryTextColor};
				background-color: transparent;
			`
		}

		if (kind === 'surface') {
			return css`
				color: ${theme.colors.light.primaryTextColor};
				background-color: ${theme.colors.light.primaryBackgroundColor};
			`
		}

		if (kind === 'destructive') {
			return css`
				color: ${theme.colors.light.toast.error.foreground};
				background-color: ${theme.colors.light.toast.error.background};
			`
		}

		if (kind === 'destructive-text') {
			return css`
				color: ${theme.colors.light.toast.error.background};
				background-color: transparent;
			`
		}

		if (kind === 'destructive-secondary') {
			return css`
				color: ${theme.colors.light.toast.error.background};
				background-color: ${theme.colorPalette.red[50]};
			`
		}
	}}

	${({ loading }) =>
		loading
			? css`
					color: transparent;
			  `
			: css`
					&:disabled {
						opacity: 0.75;
					}
			  `}
`

const Loader = styled.span<Pick<Props, 'kind'>>`
	position: absolute;
	width: 1em;
	height: 1em;
	top: calc(50% - 0.5em);
	left: calc(50% - 0.5em);
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	border-right: 3px solid transparent;
	animation: ${rotation} 750ms linear infinite;
	border-top: 3px solid
		${({ theme, kind }) => {
			if (kind === 'primary') {
				return theme.colors.light.surface
			}

			if (kind === 'destructive') {
				return theme.colors.light.surface
			}

			if (kind === 'destructive-secondary') {
				return theme.colors.light.toast.error.background
			}

			if (kind === 'destructive-text') {
				return theme.colors.light.toast.error.background
			}

			return theme.colors.dark.surface
		}};
`

function Button({ loading, children, icon, kind, disableHover = false, disabled, ...restProps }: Props) {
	return (
		<StyledButton
			loading={loading}
			kind={kind}
			disableHover={disableHover}
			disabled={disabled || loading}
			{...restProps}
		>
			<>
				{children}
				{loading && <Loader kind={kind} />}
				{icon}
			</>
		</StyledButton>
	)
}

export default Button
