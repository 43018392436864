import * as React from 'react'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'

import { noop } from '@livechat/data-utils'
import { extendURLByQueryParams, getOrigin } from '@livechat/url-utils'

const TemplateIdContext = React.createContext<{
	withTemplateId: (urlOrHref: string) => string
	setUrlQueryTemplateId: (templateId: number) => void
	templateId: number
}>({
	withTemplateId: (urlOrHref: string) => urlOrHref,
	setUrlQueryTemplateId: noop,
	templateId: 0,
})

const useTemplateId = () => React.useContext(TemplateIdContext)

type ProviderProps = {
	children: React.ReactNode
}

export const TemplateIdProvider = ({ children }: ProviderProps) => {
	const router = useRouter()
	const dispatch = useDispatch()
	const searchParams = useSearchParams()
	const tid = Number(searchParams.get('tid'))
	const templateId = Number.isNaN(tid) ? 0 : tid

	React.useEffect(() => {
		dispatch({ type: 'state/reset' })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [templateId])

	const setUrlQueryTemplateId = React.useCallback(
		(newTemplateId: number) => {
			router.push({ query: { ...router.query, tid: newTemplateId } }, undefined, {
				shallow: true,
			})
		},
		[router],
	)

	const withTemplateId = React.useCallback(
		(urlOrHref: string) => {
			if (!searchParams.has('tid')) {
				return urlOrHref
			}

			if (getOrigin(urlOrHref) !== null) {
				return extendURLByQueryParams(urlOrHref, { tid: templateId.toString() })
			}

			const paramGlue = urlOrHref.includes('?') ? '&' : '?'
			return `${urlOrHref}${paramGlue}tid=${templateId}`
		},
		[templateId, searchParams],
	)

	const value = { withTemplateId, templateId, setUrlQueryTemplateId }

	return <TemplateIdContext.Provider value={value}>{children}</TemplateIdContext.Provider>
}

export default useTemplateId
